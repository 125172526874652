import React, { useEffect, useRef } from "react"
import ScrollDown from "./../images/svg/scroll-down.svg"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MeetYeti from "./../images/svg/meet-yeti.svg"
import ProductionInside from "./../images/svg/production-inside.svg"
import ProductionInsideMobile from "./../images/svg/production-inside-m.svg"
import YetiFurPattern from "./../images/yeti-fur-dk.jpg"
import Header from "../components/header";

gsap.registerPlugin(ScrollTrigger);

const YetiIntro = ({ handleClick, loopingVid, loopingVidPoster, loopingVidMobile, loopingVidPosterMobile }) => {

  const vidYediWrap = useRef(),
        vidWrap = useRef(),
        scrollCTA = useRef(),
        yetiShape = useRef(),
        yetiShapeTablet = useRef(),
        yetiShapeMobile = useRef(),
        meetYeti = useRef(),
        prodInside = useRef(),
        prodInsideMobile = useRef(),
        cta = useRef(),
        vidRef = useRef(),
        vidRefMobile = useRef();

  useEffect(() => {
    vidRef.current.defaultMuted = true;
    vidRefMobile.current.defaultMuted = true;
  })
 
  useEffect(() => {
    
    const meetYetiTl = gsap.timeline({paused: true})
      .to(meetYeti.current, {autoAlpha: 1, duration: .5})
      .to(prodInside.current, {x: 0, duration: .5, ease: 'power2.easeOut'})
      .to(cta.current, {autoAlpha: 1, duration: .2})
      
    const meetYetiMobileTl = gsap.timeline({paused: true})
      .to(meetYeti.current, {autoAlpha: 1, duration: .5})
      .to(prodInsideMobile.current, {x: 0, duration: .5, ease: 'power2.easeOut'})
      .to(cta.current, {autoAlpha: 1, duration: .2}, '+=.5')   

    let direction = 1;
    function getDir(self) {
      direction = self.direction;
    }
    
    const playMeetYeti = () => direction > 0 ? meetYetiTl.play() : meetYetiTl.timeScale(5).reverse();
    const playMeetYetiMobile = () => direction > 0 ? meetYetiMobileTl.play() : meetYetiMobileTl.timeScale(5).reverse();
    
    const tl = gsap.timeline();
    const tltablet = gsap.timeline();
    const tlmobile = gsap.timeline();
    
    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 1024px)": function() {
        tl.set(yetiShape.current, {scale: 6, transformOrigin: '36% 100%'})
        .to(scrollCTA.current, {autoAlpha: 0, duration: .2})
        .to(yetiShape.current, {scale: 1, duration: 3})
        .add(playMeetYeti, '-=.6')
        .set({}, {}, "+=.5") // make scrolling not unpin right away
 
        ScrollTrigger.create({
          animation: tl,
          trigger: vidYediWrap.current,
          scrub: true,
          pin: true,
          onUpdate: getDir,
          // markers: true,
        })
      },

      //tablet
      "(min-width: 768px) and (max-width: 1023px)": function() {
        tltablet.set(yetiShapeTablet.current, {scale: 7, transformOrigin: '50% 50%'})
        .to(scrollCTA.current, {autoAlpha: 0, duration: .2})
        .to(yetiShapeTablet.current, {scale: 1, duration: 3})
        .add(playMeetYeti, '-=.6')
        .set({}, {}, "+=.5") // make scrolling not unpin right away
 
        ScrollTrigger.create({
          animation: tltablet,
          trigger: vidYediWrap.current,
          scrub: true,
          pin: true,
          onUpdate: getDir,
          // markers: true,
        })
      },
    
      // mobile
      "(max-width: 767px)": function() {
        tlmobile.set(yetiShapeMobile.current, {scale: 24, transformOrigin: '50% 50%'})
        .to(scrollCTA.current, {autoAlpha: 0, duration: .2})
        .to(yetiShapeMobile.current, {scale: 1, duration: 3})
        .add(playMeetYetiMobile, '-=.6')
        .set({}, {}, "+=1") // make scrolling not unpin right away

        ScrollTrigger.create({
          animation: tlmobile,
          trigger: vidYediWrap.current,
          scrub: true,
          pin: true,
          onUpdate: getDir,
          // markers: true,
        }) 
      },      
    });

  }, []);

  return (
    <>
      <div ref={vidYediWrap} id="vid-yeti-wrap" className="relative top-0 left-0 w-full h-screen">

        <Header koLogo={true} />

        <div className="absolute w-full left-0 h-28 md:h-36 bg-black bg-opacity-20"></div>
        {/* vid-wrap mobile */}
        <div ref={vidWrap} id="vid-wrap" className="h-screen lg:hidden">
          <video ref={vidRefMobile} autoPlay loop playsInline muted poster={loopingVidPosterMobile} className="block w-full h-full object-cover">
            <source src={loopingVidMobile} />
          </video>
        </div>

        {/* vid-wrap desktop */}
        <div ref={vidWrap} id="vid-wrap" className="h-screen hidden lg:block ">
          <video ref={vidRef} autoPlay loop playsInline muted poster={loopingVidPoster} className="block w-full h-full object-cover">
            <source src={loopingVid} />
          </video>
        </div>

        {/* yeti wrap DESKTOP */}
        <div className="hidden lg:block overflow-hidden absolute left-0 top-0 w-full h-full">
          <div className="object-cover" style={ {width: '1600', height: '900px', objectPosition: 'center bottom'} }>
            <svg className="yeti-mask" id="svg-shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900" preserveAspectRatio="xMidYMid slice">
              <defs>
                <defs>
                  <pattern id="yetiFurPattern" patternUnits="userSpaceOnUse" width="200" height="200">
                    <image href={YetiFurPattern} x="0" y="0" width="200" height="200" />
                  </pattern>
                </defs>
  
                <mask id="mask" x="0" y="0" width="100%" height="100%">
                  <rect x="0" y="0" width="100%" height="100%" />
                  <path fill="none" d="M0 0h1600v900H0z" />
                  <g ref={yetiShape} id="yeti-shape">
                    <path shapeRendering="crispEdges" fill="none" d="M0 0h1600v900H0z"/>
                    <path shapeRendering="crispEdges" d="M588.86 310.06a9.56 9.56 0 1116.75-6.27 9.18 9.18 0 01-.23 2 9.52 9.52 0 01-16.52 4.23zM619.67 358.07a24.44 24.44 0 01.21 3 23.78 23.78 0 01-23.24 23.81h-.6a23.84 23.84 0 1123.63-26.82z"/>
                    <path shapeRendering="crispEdges" d="M895.91 650.11c-22 29-68 36.54-97 2.67 0 0-2.85 106-12.5 247.22H403.49c-9.68-141.25-12.57-247.31-12.57-247.31-3.07 10.66-6.54 122.55-3.86 247.31H242c5.91-109.19 26.75-247.23 91.51-367.1 33.18-61.43 72.37-92.85 97.54-167.72 8.21-24.42 41.08-165.88 163.88-165.88 134 0 163.35 157.8 166 165.88 34 104.17 108.23 170.15 115.7 178.4 48.21 53.22 29.93 92.52 19.28 106.53zm-250-369.61a5.17 5.17 0 00-5.16-5.17h-89.7a5.17 5.17 0 00-4.86 5.14V431a5.18 5.18 0 005.16 5.17h30.71v71.59a60.93 60.93 0 0128-51.26v-20.33h30.72a5.18 5.18 0 005.16-5.17zM596 325a36.11 36.11 0 1034.26 24.85A35.89 35.89 0 00596 325zm12.22 61.76a28.42 28.42 0 01-12.22 2.77c-.67 0-1.32 0-2-.1a28.46 28.46 0 1129.76-34.57 28 28 0 01.7 6.19 28.47 28.47 0 01-16.2 25.7z"/>
                  </g>
                </mask>
              </defs>
  
              <rect x="0" y="0" width="100%" height="100%" />
              <rect id="fur" x="0" y="0" width="100%" height="100%" />
            </svg>
          </div>
        </div>

        {/* yeti wrap TABLET */}
        <div className="hidden md:block lg:hidden overflow-hidden absolute left-0 top-0 w-full h-full">
          <div className="object-cover object-center" style={ {width: '1023px', height: '1024px'} }>
            <svg className="yeti-mask-t" id="svg-shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1023 1024" preserveAspectRatio="xMidYMid slice">
              <defs>
                <defs>
                  <pattern id="yetiFurPattern-t" patternUnits="userSpaceOnUse" width="200" height="200">
                    <image href={YetiFurPattern} x="0" y="0" width="200" height="200" />
                  </pattern>
                </defs>
          
                <mask id="mask-t" x="0" y="0" width="100%" height="100%">
                  <rect x="0" y="0" width="100%" height="100%" />
                  <path fill="none" d="M0 0h1023v1024H0z" />
                  <g ref={yetiShapeTablet} id="yeti-shape-t">
                  <path shapeRendering="crispEdges" d="M685.49 353c-4.48-4.95-49-44.47-69.31-106.88-1.57-4.84-19.17-99.36-99.43-99.36-73.56 0-93.25 84.73-98.17 99.36C403.5 291 380 309.83 360.15 346.63c-68.78 127.32-54.85 288.85-55 327.62 0 3.51 1.4 16.18 7.85 25.11 11.22 15.51 29.27 15.45 29.27 15.45h59.83c-14.46-101.73-10.67-285.65-7.56-296.43 0 0 4.1 150.29 20.8 296.43h69.22s15.55-1 11.28-17.88c-5.64-22.29 3.25-34 13.32-37.75a21.72 21.72 0 017.59-1.41v.06a22.09 22.09 0 0119.53 12.1c3.25 6.26 4.4 15.14 1.39 27a17.78 17.78 0 00-.23 9.29c2.28 8 11.51 8.59 11.51 8.59h69.27C634.92 568.73 639 418.44 639 418.44c17.35 20.29 44.89 15.76 58.1-1.6 6.33-8.39 17.28-31.94-11.61-63.84zm-138.21-67.42a3.1 3.1 0 01-3.09 3.1h-18.4v12.18a36.49 36.49 0 00-16.74 30.71v-42.89h-18.4a3.11 3.11 0 01-3.1-3.1v-90.16a3.1 3.1 0 012.91-3.07h53.73a3.1 3.1 0 013.09 3.09zm-34.16-72.45a5.62 5.62 0 01-1.44-3.75 5.74 5.74 0 1111.47 0 5.43 5.43 0 01-.14 1.22 5.7 5.7 0 01-9.89 2.53zm18.46 28.77a14.92 14.92 0 01.12 1.78 14.23 14.23 0 01-13.92 14.26h-.36a14.28 14.28 0 1114.16-16.06zm-14.16-19.82A21.63 21.63 0 10537.94 237a21.46 21.46 0 00-20.52-14.92zm7.32 37a17 17 0 01-7.32 1.67c-.4 0-.79 0-1.19-.06A17.05 17.05 0 11534.06 240a17 17 0 01-9.32 19.1z"/>
                  </g>
                </mask>
              </defs>
          
              <rect x="0" y="0" width="100%" height="100%" />
              <rect id="fur-t" x="0" y="0" width="100%" height="100%" />
            </svg>
          </div>
        </div>

        {/* yeti wrap MOBILE */}
        <div className="md:hidden overflow-hidden absolute left-0 top-0 w-full h-full">
          <div className="object-cover object-center" style={ {width: '1023px', height: '1024px'} }>
            <svg className="yeti-mask-m" id="svg-shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 830" preserveAspectRatio="xMidYMid slice">
              <defs>
                <defs>
                  <pattern id="yetiFurPattern-m" patternUnits="userSpaceOnUse" width="200" height="200">
                    <image href={YetiFurPattern} x="0" y="0" width="200" height="200" />
                  </pattern>
                </defs>
  
                <mask id="mask-m" x="0" y="0" width="100%" height="100%">
                  <rect x="0" y="0" width="100%" height="100%" />
                  <path fill="none" d="M0 0h767v830H0z" />
                  <g ref={yetiShapeMobile} id="yeti-shape-m">
                  <path shapeRendering="crispEdges" d="M513.45 255.37c-3.19-3.52-34.89-31.69-49.4-76.18-1.13-3.45-13.67-70.83-70.88-70.83-52.43 0-66.47 60.4-70 70.83-10.75 32-27.48 45.39-41.65 71.62-49 90.75-39.1 205.9-39.23 233.53 0 2.5 1 11.53 5.61 17.9 8 11.06 20.86 11 20.86 11h42.66c-10.31-72.51-7.61-203.61-5.39-211.3 0 0 2.92 107.13 14.82 211.3h49.34s11.09-.71 8-12.75c-4-15.89 2.33-24.2 9.5-26.9a15.43 15.43 0 015.41-1 15.75 15.75 0 0113.92 8.63c2.32 4.46 3.14 10.79 1 19.28a12.59 12.59 0 00-.16 6.62c1.62 5.7 8.2 6.13 8.2 6.13h49.44C477.4 409.13 480.28 302 480.28 302c12.37 14.46 32 11.24 41.41-1.14 4.55-5.98 12.31-22.77-8.24-45.49zm-98.52-48.07a2.21 2.21 0 01-2.2 2.2h-13.12v8.68a26 26 0 00-11.93 21.89V209.5h-13.11a2.21 2.21 0 01-2.21-2.2V143a2.2 2.2 0 012.07-2.19h38.3a2.21 2.21 0 012.2 2.2zm-24.35-51.64a4.07 4.07 0 013.07-6.77 4.09 4.09 0 014.09 4.09 4.14 4.14 0 01-.1.87 4.07 4.07 0 01-7.06 1.81zm13.16 20.5a10.69 10.69 0 01.09 1.27 10.18 10.18 0 01-9.93 10.17h-.25a10.18 10.18 0 1110.09-11.45zM393.65 162a15.39 15.39 0 00-2.57 30.57 15.54 15.54 0 002.57.23 15.44 15.44 0 0015.4-15.4 15.42 15.42 0 00-15.4-15.4zm5.22 26.37a12 12 0 01-5.22 1.19h-.85a12.17 12.17 0 116.07-1.15z"/>
                  </g>
                </mask>
              </defs>
  
              <rect x="0" y="0" width="100%" height="100%" />
              <rect id="fur-m" x="0" y="0" width="100%" height="100%" />
            </svg>
          </div>
        </div>

        {/* MEET YETI LOCKUP */}
        <div className="meet-yeti">
          <div role="heading" aria-level="1" ref={meetYeti} className="meet-yeti-lockup opacity-0 relative"> 
            <MeetYeti />
            <div className="absolute inside-wrap">
              <div ref={prodInside} className="hidden md:block" style={ {transform: 'translateX(-110%)'} }>
                <ProductionInside />
              </div>
              <div ref={prodInsideMobile} className="md:hidden" style={ {transform: 'translateX(-110%)'} }>
                <ProductionInsideMobile />
              </div>
            </div>
          </div>
          <button onClick={handleClick} onKeyPress={handleClick} ref={cta} className="opacity-0 btn bg-pink mt-10 lg:mt-16 whitespace-nowrap">View Our Reel</button>
        </div>

        {/* SCROLL DOWN CTA */}
        <div ref={scrollCTA} 
          className="absolute bottom-32 lg:bottom-8 w-full">
          <button onClick={handleClick} onKeyPress={handleClick} className="btn bg-pink whitespace-nowrap">View Our Reel</button>
          <p className="text-white text-base text-center pt-8">Scroll Down</p>
          <div className="mx-auto mt-1 w-6">
            <ScrollDown />
          </div>
        </div>
      </div>
    </>
  )
}

export default YetiIntro
