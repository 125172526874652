import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AllWorkFooter from "../components/allWorkFooter"
import YetiIntro from "../components/yetiIntro"
import Video from "../components/video"
import VideoOverlay from "../components/videoOverlay"
import useToggleState from "../hooks/useToggleState"
import Fade from "react-reveal/Fade"
import YetiFurPattern from "./../images/yeti-fur-dk.jpg"

// import { graphql } from "gatsby"

const IndexPage = ({ data }) => {

  const hightLightReel = {
    title: data.strapiHomepage.highlight_reel_title,
    vimeoID: data.strapiHomepage.highlight_reel_vimeoID
  }

  const [overlayOpen, toggle] = useToggleState(false);

  const handleClick = () => {
    toggle();
  }


  return (
    <Layout>

      <Seo title="Home" />
      {/* header is placed in the intro component so can be pinned with the scrollTrigger scrubbed animtion  */}
      <YetiIntro 
        handleClick={handleClick} 
        loopingVid={data.strapiHomepage.looping_vid.publicURL} 
        loopingVidPoster={data.strapiHomepage.looping_vid_poster.publicURL}
        loopingVidMobile={data.strapiHomepage.looping_vid_mobile.publicURL} 
        loopingVidPosterMobile={data.strapiHomepage.looping_vid_poster_mobile.publicURL}
      />

      <div className="text-center pt-20 lg:pt-40 pb-4 md:pb-10 lg:pb-20" style={ {background: `url(${YetiFurPattern})`, transform: 'translateY(-6px)'} }>
        <Fade duration={500}>
          <h2 className="text-4xl md:text-4.5xl lg:text-7xl font-bold gradient-text inline-block mx-auto">Featured Work</h2>
        </Fade>
      </div>

      <div className="grid md:grid-cols-2">
          {data.strapiHomepage.featuredvideos.map(video => <Video key={video.title} video={video} />)}
      </div>

      <AllWorkFooter />
      
      {overlayOpen && (
        <VideoOverlay handleClose={handleClick} video={hightLightReel} />
      )}

    </Layout>
  )
};

export default IndexPage

export const query = graphql`
  query {
    strapiHomepage {
      highlight_reel_title
      highlight_reel_vimeoID
      looping_vid {
        publicURL
      }
      looping_vid_mobile {
        publicURL
      }
      looping_vid_poster {
        publicURL
      }
      looping_vid_poster_mobile {
        publicURL
      }
      featuredvideos {
        id
        client
        animator
        director
        editor
        photographer
        producer
        retoucher
        shooter
        photography {
          url
          alternativeText
          formats {
            large {
              publicURL
              relativePath
            }
          }
        }
        poster_image {
          publicURL
        }
        title
        vimeoID
        categories {
          category_name
        }
      }
    }
  }
`
